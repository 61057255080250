

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../../config";
import Axios from "axios";
import Modal from "vue-js-modal";
@Component
export default class EmployeeDetail extends Vue {
//   public organisationInfo() {
	
//     try {
//       this.$router.push({
//         path: "/organisation-info",
//         query: { type: "edit" },
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   }

  mounted() {}
}
